import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './third-party-login.styled';
import { IconGoogle } from '../../../../app/icons/google';
import { Divider } from '../../../../app/divider/divider';
import { Button } from '../../../../app/form/button/button';

export const ThirdPartyLogin: React.FC = observer(() => {
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <>
            <Divider gap="large">{t('dialogs.common.or')}</Divider>

            <Wrapper>
                <Button
                    block
                    onClick={() => router.push(`${process.env.NEXT_PUBLIC_API_URL}/auth/google`)}
                    variant="google"
                    icon={<IconGoogle />}
                >
                    {t('dialogs.common.loginThirdParty')}
                </Button>
            </Wrapper>
        </>
    );
});
